import styled from "styled-components";
import { Footer } from "./Footer";
import {
  Header,
  DesktopHeaderHeight,
  TabletHeaderHeight,
  MobileHeaderHeight,
} from "./Header";
import icon from "./assets/contact/bx_bx-map.svg";
import mail from "./assets/contact/ant-design_mail-outlined.svg";
import logo from "./assets/contact/logo.svg";
import google_play from "./assets/contact/google_play.svg";
import app_store from "./assets/contact/app_store.svg";

import { desktop, tablet, mobile } from "./util/breakpoints";
const Container = styled.div``;

const FirstSection = styled.div`
  padding: 160px 170px;
  background: #504ab8;
  text-align: center;
  ${tablet`
  padding: 132px 130px;
  `}
  ${mobile`
  padding: 70px 76px;
  `}
`;

const SecondSection = styled.div`
  padding: 88px 88px;
  background: #28a491;
  text-align: center;

  > img {
    width: 200px;
    ${tablet`
    width: 167px;
  `}
    ${mobile`
  width: 180px;
  `}
  }
`;

const ContactUS = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 62px;
  letter-spacing: 0.03em;
  font-feature-settings: "liga" off;
  color: #ffffff;
  margin-bottom: 110px;

  ${tablet`
    font-size: 34.825px;
    line-height: 52px;
    margin-bottom: 91px;
  `}
  ${mobile`
  font-size: 12px;
line-height: 18px;
margin-bottom: 60px;
`}
`;
const Text = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  ${tablet`
    font-size: 13.2667px;
    line-height: 19px;
    margin-top: 16px;
    margin-bottom: 21.3px;
`}
  ${mobile`
font-size: 12px;
line-height: 23px;
margin-top: 20px;
    margin-bottom: 25px;
`}
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  > a {
    display: block;
  }
  > a:first-child {
    margin-right: 30px;
  }

  margin-top: 50px;
  img {
    width: 200px;
  }

  ${tablet`
    margin-top: 40px;
    img {
      width: 165px;
    }

  `}
  ${mobile`
    margin-top: 87px;
    img {
      width: 140px;
    }

  `}
`;
export const Contact = () => {
  return (
    <Container>
      <Header />
      <FirstSection>
        <ContactUS>CONTACT US</ContactUS>
        <img src={icon} />
        <Text>807, 40 Chunggaechunro, Joonggu, Seoul</Text>
        <img src={mail} />
        <Text>elanahan@naver.com</Text>
      </FirstSection>
      <SecondSection>
        <img src={logo} />
        <ButtonWrapper>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=me.coolp&hl=ko&gl=US"
          >
            <img src={google_play} />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/kr/app/%EC%BF%A8%ED%94%BC-%EB%AA%A8%EB%91%90%EC%9D%98-%EC%97%AC%ED%96%89%EC%98%81%EC%83%81/id1518035643"
          >
            <img src={app_store} />
          </a>
        </ButtonWrapper>
      </SecondSection>
      <Footer />
    </Container>
  );
};
