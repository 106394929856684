import styled from "styled-components";
import { mobile, tablet } from "./util/breakpoints";
import backBar from "./assets/5/5-back-bar.png";
import { useRef } from "react";
import { Zoom, Fade } from "react-reveal";
import icon from "./assets/5/5-icon.png";
import wave from "./assets/5/wave-purple.png";

const Container = styled.div`
  background: white;
  padding: 224px 39px;
  position: relative;

  display: grid;
  grid-template-columns: 1fr 577px 1fr;

  ${tablet`
    padding: 110px 40px;
    grid-template-columns: 1fr 400px 1fr;

  `}

  ${mobile`
    display: flex;
    flex-direction: column;
    padding: 70px 36px;
  `}
`;

const MiddleBarWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -288px;
  width: 577px;
  z-index: 0;
  ${tablet`
    display: none;
  `}

  ${mobile`
    display: none;
  `}
`;

const MiddleBar = styled.img`
  width: 100%;
  z-index: 0;
`;

const Wave = styled.img`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  ${mobile`
    display: none;
  `}
`;

const MiddleImage = styled.img`
  position: relative;
  width: 500px;
  z-index: 3;

  ${tablet`
    width: 417px;
  `}

  ${mobile`
    width: 262px;
  `}
`;

const LeftText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 49px;
  text-align: right;
  font-feature-settings: "liga" off;
  color: #333;
  display: flex;
  justify-content: center;
  margin-right: 59px;
  flex-direction: column;

  ${tablet`
    font-size: 25px;
    line-height: 37px;
    margin-right:36px;
  `}

  ${mobile`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-right: 0;
    margin-bottom: 50px;
  `}
`;

const MobileBR = styled.br`
  display: none;
  ${mobile`
    display: inline;
  `}
`;
const RightText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 31px;
  text-align: left;
  font-feature-settings: "liga" off;
  color: #333;
  display: flex;
  justify-content: center;
  margin-left: 59px;
  flex-direction: column;

  ${tablet`
    font-size: 15px;
    line-height: 22px;
    margin-left: 36px;
  `}

  ${mobile`
    font-size: 9px;
    line-height: 13px;
    text-align: center;
    margin-left: 0;
    margin-top: 50px;
  `}
`;

export const Section5 = () => {
  const lottieRef = useRef(null);
  return (
    <Container>
      <LeftText>
        <Fade bottom>
          Just know where to go then you’ll find what to do there!
        </Fade>
      </LeftText>
      <div style={{ position: "relative", zIndex: 3 }}>
        <Zoom center fraction={0.5}>
          <div style={{ textAlign: "center" }}>
            <MiddleImage src={icon} />
          </div>
        </Zoom>
      </div>
      <RightText>
        <Fade bottom>
          <p>
            You only need to know the name of the destination or a travel theme.
          </p>
          <br />
          <p>
            You will see all the things to do at ‘the place’ just by sliding the
            videos.
          </p>
          <br />
          <p>
            All the videos have the name of the place tagged on it and more
            information will show when touched.
          </p>
        </Fade>
      </RightText>
      <MiddleBarWrapper>
        <Fade bottom>
          <MiddleBar src={backBar} />
        </Fade>
      </MiddleBarWrapper>
      <Wave src={wave} />
    </Container>
  );
};
