import styled from "styled-components";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { desktop, tablet, mobile } from "./util/breakpoints";
import {
  DesktopHeaderHeight,
  TabletHeaderHeight,
  MobileHeaderHeight,
} from "./Header";
import promotion1 from "./assets/promotion/promotion1.jpg";
import promotion2 from "./assets/promotion/promotion2.jpg";

const Container = styled.div`
  padding: 160px 160px 190px 160px;

  background: #f2f2f2;

  ${tablet`
    padding: 132px 136px 148px 136px;
  `}

  ${mobile`
    padding: 70px 48px 64px; 
  `}
`;

const PageTitle = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 0.03em;
  font-feature-settings: "liga" off;
  color: #333333;

  margin-bottom: 102px;

  ${tablet`
    font-size: 34.825px;
    line-height: 52px;
    margin-bottom: 84px;
  `}

  ${mobile`
    font-size: 12px;
    line-height: 18px;  
    margin-bottom: 4.1px;
  `}
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  > div:first-child {
    margin-right: 110px;
    ${tablet`
    margin-right: 91px;
    `}
  }
  ${mobile`
    flex-direction: column;
    >div:first-child{
      margin-right: 0;
      margin-bottom: 75px;
    }
  `}
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  font-feature-settings: "liga" off;
  color: #18191f;

  margin-bottom: 24px;
  ${tablet`
    font-size: 23.2167px;
    line-height: 33px;
    margin-bottom: 20px;
  `}

  ${mobile`
    font-size: 9px;
    line-height: 22px;
    margin-bottom: 13px;
  `}
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: "liga" off;
  color: #18191f;

  ${tablet`
    margin-top: 20px;
    >p{
      font-size: 11.6083px;
      line-height: 15px;
    }
  `}

  ${mobile`
    margin-top: 13.44px;
    font-size: 7.84px;
    line-height: 10px;
  `}
`;

const Button = styled.button`
  border-radius: 100px;
  padding: 10px 38px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "liga" off;
  color: #ffffff;
  background: ${({ enabled }) => (enabled ? "FF0088" : "#e0e0e0")};
  border: 0;

  ${tablet`
    font-size: 11.6083px;
    line-height: 17px;  
   
  `}

  ${mobile`
    padding: 5px 21px;
    font-size: 7.84px;
    line-height: 11px;  
  `}
`;

const Image = styled.img`
  width: 500px;

  ${tablet`
    width: 100%;
  `}

  ${mobile`
    width: 100%;
  `}
`;

export const Promotion = () => {
  return (
    <>
      <Header />
      <Container>
        <PageTitle>PROMOTION</PageTitle>
        <Grid>
          <div onClick={() => window.open(promotion1, "_blank")}>
            <Title>OPENING SOON</Title>
            <Image src={promotion1} />
            <ActionBar>
              <p>DATE</p>
              <Button enabled={false}>more</Button>
            </ActionBar>
          </div>
          <div onClick={() => window.open(promotion2, "_blank")}>
            <Title>OPENING SOON</Title>
            <Image src={promotion2} />
            <ActionBar>
              <p>DATE</p>
              <Button enabled={false}>more</Button>
            </ActionBar>
          </div>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
