import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "./assets/header/Coolp_logo.svg";
import { mobile, tablet, desktop } from "./util/breakpoints";
export const MobileHeaderHeight = 50;
export const TabletHeaderHeight = 75;
export const DesktopHeaderHeight = 100;
const Header1 = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 39px 50px;
  img {
    width: 71px;
  }
  > div > a {
    text-decoration: none;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: #000000;
    &:not(:last-child) {
      margin-right: 40px;
      ${tablet`
     margin-right: 27px;
    `}
      ${mobile`
      margin-right: 14px;
    `}
    }
    ${tablet`
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.03em;
      color: #000000;
    `}
    ${mobile`
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.03em;
    color: #000000;
  `}
  }

  ${tablet`
    height: 75px;
     > img {
        width: 55px;
    }
    padding: 30px 35px;
    `}

  ${mobile`
    height: 50px;
     > img {
        width: 40px;
    }
    padding: 19px 18px;
  `}
`;

const Placeholder = styled.div`
  ${desktop`
   height: ${DesktopHeaderHeight}px;
  `}
  ${tablet`
  height: ${TabletHeaderHeight}px;
  `}
   ${mobile`
   height: ${MobileHeaderHeight}px;
  `}
`;
export const Header = ({ section2Ref }) => {
  const [showHeader, setShowHeader] = useState(!section2Ref);

  const handleScroll = useCallback(() => {
    if (section2Ref) {
      if (
        section2Ref.current &&
        section2Ref.current.offsetTop > window.scrollY
      ) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    }
  }, [section2Ref]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  if (!showHeader) return null;
  return (
    <>
      <Header1>
        <Link to="/">
          <img src={logo} />
        </Link>
        <div>
          <Link to="/promotion">Promotion</Link>
          <Link to="/contact">Contact</Link>
          <a
            target="_blank"
            href="https://coolpapp.page.link/apps?utm_source=owned&utm_medium=banner&utm_campaign=homepage"
          >
            Download
          </a>
        </div>
      </Header1>
      <Placeholder />
    </>
  );
};
