import styled from "styled-components";
import { mobile, tablet } from "./util/breakpoints";
import backBar from "./assets/9/back-bar.png";
import middleImg from "./assets/9/icon.png";
import { Zoom, Fade } from "react-reveal";

const Container = styled.div`
  background: #28a491;
  padding: 120px 39px;
  position: relative;

  display: grid;
  grid-template-columns: 1fr 577px 1fr;

  ${tablet`
    padding: 110px 40px;
    grid-template-columns: 1fr 400px 1fr;

  `}

  ${mobile`
    display: flex;
    flex-direction: column;
    padding: 70px 36px;
  `}
`;

const MiddleBarWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -288px;
  width: 577px;
  z-index: 0;
  ${tablet`
    display: none;
  `}

  ${mobile`
    display: none;
  `}
`;

const MiddleBar = styled.img`
  width: 100%;
  z-index: 0;
`;

const MiddleImage = styled.img`
  position: relative;
  width: 500px;
  z-index: 3;

  ${tablet`
    width: 400px;
  `}

  ${mobile`
    width: 100%;
  `}
`;

const LeftText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 49px;
  text-align: right;
  font-feature-settings: "liga" off;
  display: flex;
  justify-content: center;
  margin-right: 33px;
  flex-direction: column;
  color: white;
  ${tablet`
    font-size: 25px;
    line-height: 37px;
    margin-right: 0;
  `}

  ${mobile`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-right: 0;
    margin-bottom: 30px;
  `}
`;

const RightText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 31px;
  text-align: left;
  font-feature-settings: "liga" off;
  color: #333333;
  display: flex;

  justify-content: center;
  margin-left: 33px;
  flex-direction: column;
  color: white;

  ${tablet`
    font-size: 15px;
    line-height: 22px;
    margin-left: 0;
  `}

  ${mobile`
    font-size: 9px;
    line-height: 13px;
    text-align: center;
    margin-left: 0;
    margin-top: 59px;
  `}
`;

export const Section9 = () => {
  return (
    <Container>
      <LeftText>
        <Fade bottom>Book your bucketlist</Fade>
      </LeftText>
      <div style={{ position: "relative", zIndex: 3 }}>
        <Zoom center fraction={0.5}>
          <div style={{ textAlign: "center" }}>
            <MiddleImage src={middleImg} />
          </div>
        </Zoom>
      </div>
      <RightText>
        <Fade bottom>
          <p>Fill in your bucket list with all the places You want to visit.</p>
          <br />
          <p>
            You will be rewarded when you fulfil your bucketlist and post your
            experiences on CoolP.
          </p>
          <br />
          <p>Book the best offer to get to your bucketlist.</p>
        </Fade>
      </RightText>
      <MiddleBarWrapper>
        <Fade bottom>
          <MiddleBar src={backBar} />
        </Fade>
      </MiddleBarWrapper>
    </Container>
  );
};
