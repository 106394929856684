import styled from "styled-components";
import { mobile, tablet } from "./util/breakpoints";
import instagramLogo from "./assets/footer/instagram-logo.svg";

const Container = styled.div`
  background-color: #333333;
  padding: 68px 80px 65px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${tablet`
    padding: 120px 87px;
  `}
  ${mobile`
    padding: 38px 30px;
  `}
`;

const CompanyInfo = styled.div`
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  font-feature-settings: "liga" off;
  color: #ffffff;
  > p {
    margin: 0;
  }
  > .name {
  }
  > .info {
    margin-top: 24px;
  }
  > .copyright {
    margin-top: 24px;
    font-size: 14px;
    line-height: 24px;
    color: #ff0088;
  }
  ${mobile`
    >p{
      font-size: 9px !important;
      line-height: 11px !important;
    }
  `}
`;

const InstagramLogo = styled.img`
  ${tablet`
     width: 40px;
  `}
  ${mobile`
    width: 30px;
  `}
`;

export const Footer = () => {
  return (
    <Container>
      <CompanyInfo>
        <p className="name">CoolP</p>
        <p className="info">
          HOTSUNNY INC
          <br /> 807, 40 Chunggaechunro, Joonggu, Seoul
          <br />
          Mail : elanahan@naver.com
        </p>
        <p className="copyright">Copyright © Coolp Inc. All rights reserved.</p>
      </CompanyInfo>
      <InstagramLogo src={instagramLogo} />
    </Container>
  );
};
