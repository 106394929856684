import { Footer } from "./Footer";
import { Section1 } from "./Section1";
import { Section10 } from "./Section10";
import { Section11 } from "./Section11";
import { Section2 } from "./Section2";
import { Section3, Section4 } from "./Section4";
import { Section7 } from "./Section7";
import { Section8 } from "./Section8";
import { Section9 } from "./Section9";
import { ParallaxProvider } from "react-scroll-parallax";
import { Section6 } from "./Section6";
import { Section5 } from "./Section5";
import { useRef } from "react";
import { Header } from "./Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Contact } from "./Contact";
import ScrollToTop from "./util/ScrollToTop";
import { Promotion } from "./Promotion";

export const App = () => {
  const section2Ref = useRef(null);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <ParallaxProvider>
              <Section1 />
              <Header section2Ref={section2Ref} />
              <Section2 ref={section2Ref} />
              <Section4 />

              <Section5 />
              <Section6 />
              <Section7 />
              <Section8 />
              <Section9 />
              <Section10 />
              <Section11 />
              <Footer />
            </ParallaxProvider>
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/promotion">
            <Promotion />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
