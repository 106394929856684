import styled from "styled-components";
import { isDesktop, mobile, tablet } from "./util/breakpoints";
import bg from "./assets/10/background.jpg";
import { ParallaxBanner, withController } from "react-scroll-parallax";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 72px;
  line-height: 107px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  ${tablet`
    font-size: 50px;
    line-height: 74px;
  `}
  ${mobile`
    font-size: 24px;
    line-height: 36px;
  `};
`;

const Banner = styled(ParallaxBanner)`
  height: 512px !important;
  ${tablet`
     height: 400px !important;
  `}
  ${mobile`
    height: 243px !important;
  `};
`;

export const Section10 = withController(({ parallaxController }) => {
  useEffect(() => {
    setInterval(() => {
      parallaxController.update();
    }, 2000);
  }, []);
  return (
    <Banner
      layers={[
        {
          image: bg,
          amount: isDesktop() ? 0.2 : 0.7,
        },
      ]}
    >
      <Container>
        OVER 1700 cities
        <br /> and various travel theme
      </Container>
    </Banner>
  );
});
