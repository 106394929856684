import styled from "styled-components";
import { mobile, tablet } from "./util/breakpoints";
import YouTube from "react-youtube";
import bgImg from "./assets/3/background.jpg";

const Container = styled.div`
  background-image: url(${bgImg});
  padding: 42px 40px;
  > div {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
`;

const YTPlayer = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export const Section4 = () => {
  return (
    <Container>
      <YTPlayer
        videoId={"dn6gDc7pJyY"}
        opts={{
          height: "100%",
          width: "100%",
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            playsinline: 1,
            mute: 1,
            playlist: "dn6gDc7pJyY",
            loop: 1,
          },
        }}
      />
    </Container>
  );
};
