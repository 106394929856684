const MOBILE_MAX = 831;
const TABLET_MAX = 1439;

export const mobile = ([style]) => `
   @media only screen and (max-width: ${MOBILE_MAX}px) {
      ${style}
  }
`;

export const tablet = ([style]) => `
   @media only screen and (min-width: ${
     MOBILE_MAX + 1
   }px) and (max-width: ${TABLET_MAX}px){
      ${style}
  }
`;

export const desktop = ([style]) => `
   @media only screen and (min-width: ${TABLET_MAX + 1}px){
      ${style}
  }
`;

export const isMobile = () => {
  console.log("MOBILE");
  return window.screen.width <= MOBILE_MAX;
};

export const isTablet = () => {
  console.log("TABLET");

  return window.screen.width <= TABLET_MAX;
};

export const isDesktop = () => {
  console.log("DESKTOP");

  return window.screen.width > MOBILE_MAX;
};
