import { Player } from "@lottiefiles/react-lottie-player";
import styled from "styled-components";
import animationLottie from "./assets/11/11.json";
import { mobile, tablet } from "./util/breakpoints";
import { Waypoint } from "react-waypoint";
import { useRef } from "react";

const Container = styled.div`
  padding: 55px 95px;
  ${tablet`
    padding: 52px 97px;
  `}
  ${mobile`
    padding: 23px 12px;
  `}
`;
const Animation = styled(Player)``;

export const Section11 = () => {
  const lottieRef = useRef(null);
  return (
    <Container>
      <Waypoint
        onEnter={() => {
          lottieRef.current && lottieRef.current?.play();
        }}
        onLeave={() => {
          lottieRef.current && lottieRef.current?.pause();
        }}
      >
        <div>
          <Animation
            ref={lottieRef}
            autoplay
            loop
            src={animationLottie}
            style={{ width: "100%" }}
          />
        </div>
      </Waypoint>
    </Container>
  );
};
