import { Player } from "@lottiefiles/react-lottie-player";
import leftLottie from "./assets/1/left.json";
import rightLottie from "./assets/1/right.json";
import mLottie from "./assets/1/m.json";
import cloud from "./assets/1/cloud.json";
import airplaneLottie from "./assets/1/airplane.json";
import logo from "./assets/1/logo.svg";
import styled from "styled-components";
import { mobile, tablet, desktop } from "./util/breakpoints";
import { Waypoint } from "react-waypoint";
import { useRef } from "react";

const Container = styled.div`
  div {
    ${"" /* box-sizing: border-box; */}
  }
`;

const Cloud = styled(Player)``;

const Airplane = styled(Player)`
  position: absolute;
  bottom: 10%;
  right: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ThirdGridWrapper = styled.div`
  position: relative;
`;

const Logo = styled.img`
  width: 300px;

  ${mobile`
    width: 180px;
  `}

  ${tablet`
    width: 248px;
  `}
`;

const LottieGrid = styled.div`
  display: grid;
  position: relative;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr;
  > div:not(.cloud-container) {
    padding-top: 40px;
    ${mobile`
      padding-top: 20px;
    `}
  }
  .cloud-container {
    position: absolute;
    bottom: 0px;
    left: -10px;
    right: -10px;
    display: flex;
    flex-direction: column;
    height: 70px;
    ${tablet`
      height: 110px;
    `}
    ${desktop`
      height: 170px;
    `}
    > div:first-child {
      position: relative;
      top: 10px;
    }
    .white-bg {
      flex-grow: 1;
      background: white;
    }
  }
  > div:nth-child(1) {
    background: #ef910f;
  }
  > div:nth-child(2) {
    background: #28a491;
  }
  > div:nth-child(3) {
    background: #504ab8;
  }

  ${mobile`
    grid-template-columns: 1fr 1fr;
    > div:nth-child(3) {
       display: none;
    }
  `}
`;

const TextArea = styled.div`
  padding: 126px 0;
  ${tablet`
    padding: 102px 0;
  `}
  ${mobile`
    padding: 63px 30px 100px;
  `}
`;

const Text = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #333333;

  ${mobile`
    font-size: 12px;
    line-height: 18px;
  `}

  ${tablet`
    font-size: 25px;
    line-height: 37px;
  `}
`;

const AppDownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${mobile`
       margin-top: 53px;
    `}
  ${tablet`
      margin-top: 78px;
  `}
  ${desktop`
      margin-top: 96px;
  `}
  > img:first-child {
    ${mobile`
       margin-right: 22px;
    `}
    ${tablet`
       margin-right: 24px;
    `}
    ${desktop`
       margin-right: 30px;
    `}
  }
  > img {
    ${mobile`
      width: 100px;
    `}
    ${tablet`
      width: 203px;
    `}
     ${desktop`
      width: 250px;
    `}
  }
`;

export const Section1 = () => {
  const cloudRef = useRef(null);
  return (
    <Container>
      <LottieGrid>
        <Player
          autoplay
          src={leftLottie}
          style={{ width: "100%" }}
          keepLastFrame
        />
        <Player
          autoplay
          src={mLottie}
          style={{ width: "100%" }}
          keepLastFrame
        />
        <ThirdGridWrapper>
          <Player
            autoplay
            src={rightLottie}
            style={{ width: "100%" }}
            keepLastFrame
          />
          <Airplane autoplay loop src={airplaneLottie} />
        </ThirdGridWrapper>
        <Waypoint
          onEnter={() => {
            cloudRef.current && cloudRef.current?.play();
          }}
          onLeave={() => {
            cloudRef.current && cloudRef.current?.pause();
          }}
        >
          <div className={"cloud-container"}>
            <Cloud
              renderer="svg"
              ref={cloudRef}
              autoplay
              loop
              src={cloud}
              style={{ width: "100%" }}
              rendererSettings={{
                preservereAspectRatio: "none",
              }}
            />
            <div className={"white-bg"} />
          </div>
        </Waypoint>
      </LottieGrid>
      <LogoWrapper>
        <Logo src={logo} />
      </LogoWrapper>
      <TextArea>
        <Text>
          Get ideas of what you can do at the place.
          <br />
          There is always something you could have missed.
        </Text>
        <AppDownloadButtonWrapper>
          <AppDownloadButton
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=me.coolp&hl=ko&gl=US",
                "_blank"
              )
            }
            src={"/images/1/googleplay.svg"}
            srcOnHover={"/images/1/googleplay-selected.svg"}
          />
          <AppDownloadButton
            onClick={() =>
              window.open(
                "https://apps.apple.com/kr/app/%EC%BF%A8%ED%94%BC-%EB%AA%A8%EB%91%90%EC%9D%98-%EC%97%AC%ED%96%89%EC%98%81%EC%83%81/id1518035643",
                "_blank"
              )
            }
            src={"/images/1/appstore.svg"}
            srcOnHover={"/images/1/appstore-selected.svg"}
          />
        </AppDownloadButtonWrapper>
      </TextArea>
    </Container>
  );
};

const AppDownloadButton = ({ onClick, src, srcOnHover, alt }) => (
  <img
    onClick={onClick}
    src={src}
    alt={alt}
    onMouseOver={(e) => {
      srcOnHover && (e.currentTarget.src = srcOnHover);
    }}
    onMouseOut={(e) => {
      srcOnHover && (e.currentTarget.src = src || "");
    }}
  />
);
