import styled from "styled-components";
import { Player } from "@lottiefiles/react-lottie-player";
import { mobile, tablet } from "./util/breakpoints";
import lottie from "./assets/8/8.json";
import backBar from "./assets/8/back-bar.png";
import wave from "./assets/8/wave_green.png";
import { Waypoint } from "react-waypoint";
import { useRef } from "react";
import { Zoom, Fade } from "react-reveal";

const Container = styled.div`
  background: #f2f2f2;
  padding: 120px 39px;
  position: relative;

  display: grid;
  grid-template-columns: 1fr 577px 1fr;

  ${tablet`
    padding: 110px 40px;
    grid-template-columns: 1fr 400px 1fr;

  `}

  ${mobile`
    display: flex;
    flex-direction: column;
    padding: 70px 36px;
  `}
`;

const MiddleBarWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -288px;
  width: 577px;
  z-index: 0;
  ${tablet`
    display: none;
  `}

  ${mobile`
    display: none;
  `}
`;

const MiddleBar = styled.img`
  width: 100%;
  z-index: 0;
`;

const Wave = styled.img`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;

  ${mobile`
    display: none;
  `}
`;

const Lottie = styled(Player)`
  position: relative;
  width: 500px;
  z-index: 3;

  ${tablet`
    width: 400px;
  `}

  ${mobile`
    width: 100%;
  `}
`;

const LeftText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 49px;
  text-align: right;
  font-feature-settings: "liga" off;
  color: #333333;
  display: flex;
  justify-content: center;
  margin-right: 33px;
  flex-direction: column;

  ${tablet`
    font-size: 25px;
    line-height: 37px;
    margin-right: 0;
  `}

  ${mobile`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-right: 0;
    margin-bottom: 30px;
  `}
`;

const MobileBR = styled.br`
  display: none;
  ${mobile`
    display: inline;
  `}
`;
const RightText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 31px;
  text-align: left;
  font-feature-settings: "liga" off;
  color: #333333;
  display: flex;
  justify-content: center;
  margin-left: 33px;
  flex-direction: column;

  ${tablet`
    font-size: 15px;
    line-height: 22px;
    margin-left: 0;
  `}

  ${mobile`
    font-size: 9px;
    line-height: 13px;
    text-align: center;
    margin-left: 0;
    margin-top: 59px;
  `}
`;

export const Section8 = () => {
  const lottieRef = useRef(null);
  return (
    <Container>
      <LeftText>
        <Fade bottom>
          Tidy your travel album
          <MobileBR /> by time, location and theme.
        </Fade>
      </LeftText>
      <div style={{ position: "relative", zIndex: 3 }}>
        <Zoom
          center
          fraction={0.5}
          onReveal={() => {
            setTimeout(() => {
              lottieRef.current && lottieRef.current?.play();
            }, 0);
          }}
        >
          <Lottie ref={lottieRef} src={lottie} keepLastFrame />
        </Zoom>
      </div>
      <RightText>
        <Fade bottom>
          <p>
            Cool-P will automatically sort all your travel movie clips by
            location and date.
          </p>
          <br />
          <p>
            No need to go through past mobile phones anymore. All your travel
            will be in one place tidy.
          </p>
          <br />
          <p>
            Also, one touch will share all your travel by cities and activity.
          </p>
        </Fade>
      </RightText>
      <MiddleBarWrapper>
        <Fade bottom>
          <MiddleBar src={backBar} />
        </Fade>
      </MiddleBarWrapper>
      <Wave src={wave} />
    </Container>
  );
};
