import { forwardRef, useMemo } from "react";
import styled from "styled-components";
import { isMobile, mobile, tablet } from "./util/breakpoints";
import img1 from "./assets/3/1-icon.png";
import img2 from "./assets/3/2-icon.png";
import img3 from "./assets/3/3-icon.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Container = styled.div`
  background: linear-gradient(180deg, #ffffff 31.77%, #fcedda 100%);
  padding: 160px 0 100px;
  ${tablet`
    padding: 110px 0 110px;
  `}
  ${mobile`
    padding: 88px 0 88px;
  `}
`;

const Title = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 0.03em;
  font-feature-settings: "liga" off;
  color: #333333;
  padding: 0 20px;
  margin-bottom: 166px;

  ${tablet`
    font-size: 33px;
    line-height: 49px;
    margin-bottom: 118px;
  `}
  ${mobile`
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 69px;
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 400px 400px 400px;
  grid-gap: 50px;
  justify-content: center;

  ${tablet`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  `}
`;

const Card = styled.div`
  position: relative;
  text-align: center;

  > img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    font-feature-settings: "liga" off;
    color: #333333;
    margin-bottom: 40px;

    ${tablet`
      font-size: 18px;
      line-height: 27px;
    `}
    ${mobile`
      margin-top: 33.52px;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 20px;
    `}
  }
  .border {
    width: 300px;
    height: 2px;
    background: #333333;
    margin: 0 auto 40px;

    ${tablet`
     width: 248px;
    `}
    ${mobile`
      width: 80%;
      height: 1px;
      margin: 0 auto 20px;
    `}
  }
  .desc {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #333333;
    display: block;
    padding: 0 20px;

    ${tablet`
      font-size: 15px;
      line-height: 22px;
    `}
    ${mobile`
      font-size: 9px;
      line-height: 13px;
    `}
  }

  padding-top: 166px;
  ${tablet`
    padding-top: 43%;
  `}
  ${mobile`
  box-sizing: border-box;
    position: relative;
    background: #FFFFFF;
    padding-top: 0;
    padding: 11px 22px 25px;
    > img {
      width: 100%;
      position: relative;
    }
    padding: 15;
    minHeight: 100;
    color: "#fff";
    margin: 5px 15px 5px;
    box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 25%);
  `}
`;
export const Section2 = forwardRef((props, ref) => {
  const cards = useMemo(
    () => (
      <>
        <Card>
          <img src={img1} />
          <p className="title">
            Optimized for searching travel sites and theme
          </p>
          <div className="border" />
          <p className="desc">
            Our innovative travel search module allows you to search all the
            things you can do at your destined travel site. <br /> <br /> Also
            you can find all the places to do your favorite activity with just
            one touch.
          </p>
        </Card>
        <Card>
          <img src={img2} />
          <p className="title">
            Recent travel experiences shown with movie clips
          </p>
          <div className="border" />
          <p className="desc">
            No need to look at old pictures of travel sites to get information.
            <br /> <br />
            You can easily look for recent travel experiences on CoolP. <br />
            <br />
            Someone could have visited there yesterday.
          </p>
        </Card>
        <Card>
          <img src={img3} />
          <p className="title">All videos have locations</p>
          <div className="border" />
          <p className="desc">
            All our movie clips of fantastic travel experiences have locations
            and even have address so you can go to the place one day.
          </p>
        </Card>
      </>
    ),
    []
  );

  return (
    <Container ref={ref}>
      <Title>“New guide to tell you what to do on your trip”</Title>
      {isMobile() ? (
        <Carousel
          selectedItem={0}
          centerMode
          // dynamicHeight
          centerSlidePercentage={65}
          emulateTouch
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          autoPlay={false}
        >
          <Card>
            <img src={img1} />
            <p className="title">
              Optimized for searching travel sites and theme
            </p>
            <div className="border" />
            <p className="desc">
              Our innovative travel search module allows you to search all the
              things you can do at your destined travel site. <br /> <br /> Also
              you can find all the places to do your favorite activity with just
              one touch.
            </p>
          </Card>
          <Card>
            <img src={img2} />
            <p className="title">
              Recent travel experiences shown with movie clips
            </p>
            <div className="border" />
            <p className="desc">
              No need to look at old pictures of travel sites to get
              information.
              <br /> <br />
              You can easily look for recent travel experiences on CoolP. <br />
              <br />
              Someone could have visited there yesterday.
            </p>
          </Card>
          <Card>
            <img src={img3} />
            <p className="title">All videos have locations</p>
            <div className="border" />
            <p className="desc">
              All our movie clips of fantastic travel experiences have locations
              and even have address so you can go to the place one day.
            </p>
          </Card>
        </Carousel>
      ) : (
        <Grid>{cards}</Grid>
      )}
    </Container>
  );
});
